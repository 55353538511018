import React, { useEffect, useCallback, useMemo } from 'react';
import Tooltip from '@beewise/tooltip';
import Icon from '@beewise/icon';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { useWatch } from 'react-hook-form';
import { arrayOfObjectsShallowEqual, usePermission } from '@beewise/react-utils';
import { useDispatch, useSelector } from 'react-redux';
import { SelectFormField, CheckboxFormField } from '@beewise/hook-form';
import SelectGridField from 'components/reusables/SelectGridField';
import { validateDependentFields } from './utils';
import { gridBhomeSoftwareUpdateOptions } from '../utils';
import { bhomeVersions } from '../enum';

import { fetchDeleteBhomeBundle } from '../../actions';

const ALL_VERSIONS = 'All';

const bhomeVersionsAll = [{ value: ALL_VERSIONS, label: ALL_VERSIONS }, ...bhomeVersions];

const getFullBundle = ({ bundlesDisplayed, bundle }) => {
    const bundleObject = bundlesDisplayed.find(({ id }) => id === bundle);
    return { ...bundleObject, id: bundle };
};

const RegularBhomeUpdate = ({ bhomeOptions, form }) => {
    const dispatch = useDispatch();
    const isDeleteAllowed = usePermission('deleteBundle', 'admin');

    const { setValue, control, getValues } = form;
    const [bhomeVersion, bundle] = useWatch({ name: ['bhomeVersion', 'bundle'], control });

    const bundles = useSelector(state => state.dashboard.softwareBundles, arrayOfObjectsShallowEqual);

    const bundlesDisplayed = useMemo(
        () =>
            bhomeVersion === ALL_VERSIONS ? bundles : bundles.filter(bundle => bundle.bhomeVersion === bhomeVersion),
        [bhomeVersion, bundles]
    );

    const resetFields = fields => fields.forEach(field => setValue(field, null));

    useEffect(() => {
        if (bhomeVersion) {
            resetFields(['bundle', 'bhomeIds']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bhomeVersion]);

    const bundleOptions = bundlesDisplayed.map(item => ({
        label: item.bundle.versionNumber,
        value: item.id,
    }));

    const fullBundle = getFullBundle({ bundlesDisplayed, bundle });

    const handleDeleteBundle = useCallback(() => {
        if (bundle) {
            dispatch(fetchDeleteBhomeBundle(bundle, () => setValue('bundle', null)));
        }
    }, [dispatch, bundle, setValue]);

    return (
        <div className="software-update-content">
            <div className="software-update-section">
                <SelectFormField
                    name="bhomeVersion"
                    label="Select Bhome Version"
                    options={bhomeVersionsAll}
                    className="software-update-input"
                    required
                    control={control}
                />
                <div className="row">
                    <SelectGridField
                        name="bhomeIds"
                        gridName="Select Bhomes"
                        isMulti
                        gridOptions={gridBhomeSoftwareUpdateOptions}
                        onSelect={value => setValue('bhomeIds', value)}
                        label="Select Bhome"
                        options={bhomeOptions}
                        className="software-update-input"
                        control={control}
                        isDisabled={!bhomeVersion}
                        validate={validateDependentFields('isAllBhomes', getValues)}
                        size="extra-large"
                    />
                    <CheckboxFormField
                        name="isAllBhomes"
                        className="software-update-checkbox"
                        label="All"
                        disabled={!bhomeOptions.length || !bhomeVersion}
                        control={control}
                        validate={validateDependentFields('bhomeIds', getValues)}
                    />
                </div>
                <CheckboxFormField
                    name="rebootAfterUpdate"
                    className="software-reboot-update"
                    label="Reboot after update"
                    disabled={!bhomeOptions.length}
                    control={control}
                />
                <CheckboxFormField
                    name="enforceImmediateAction"
                    className="software-reboot-update"
                    label="Enforce Immediate"
                    disabled={!bhomeOptions.length}
                    control={control}
                />
            </div>
            <div className="software-update-section">
                <div className="row">
                    <SelectFormField
                        name="bundle"
                        search
                        label="Select Bundle"
                        options={bundleOptions}
                        isDisabled={!bhomeVersion}
                        className="software-update-input software-update-select-bundle"
                        required
                        control={control}
                    />
                    {fullBundle?.note && (
                        <Tooltip
                            content={
                                <div
                                    className="text-edited"
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: fullBundle.note,
                                    }}
                                />
                            }
                            hoverable
                            position="bottom center"
                            action="click"
                            className="software-update-tooltip"
                        >
                            <Icon type="help-circle" className="help-grey" />
                        </Tooltip>
                    )}
                </div>
                {fullBundle?.bundle && (
                    <div className="software-bundle-info">
                        {Object.keys(fullBundle?.bundle)?.map(key => (
                            <div key={key}>
                                <span className="bold">{key}:</span> {fullBundle.bundle[key]}
                            </div>
                        ))}
                    </div>
                )}
                {isDeleteAllowed && bundle && (
                    <Button onClick={handleDeleteBundle} className="btn-primary">
                        Delete Bundle
                    </Button>
                )}
            </div>
        </div>
    );
};

RegularBhomeUpdate.propTypes = {
    bhomeOptions: PropTypes.arrayOf(PropTypes.shape()),
    form: PropTypes.shape(),
};

export default RegularBhomeUpdate;
