import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { useForm, useWatch } from 'react-hook-form';
import { UI_ENV } from 'config';
import { CheckboxFormField, ROOT_ERRORS, getRootLevelErrors } from '@beewise/hook-form';
import { fetchUpdateBhomeSoftware, fetchSoftwareBundles, fetchConnectivityMap } from '../../actions';
import DirectBhomeUpdate from './DirectBhomeUpdate';
import RegularBhomeUpdate from './RegularBhomeUpdate';
import { getBhomeOptions, ALL_VERSIONS } from '../utils';
import './BhomeSoftwareUpdate.scss';

const isTestEnv = ['dev', 'lab', 'stage'].includes(UI_ENV);

const defaultValues = {
    isDirectUpdate: false,
    bhomeVersion: null,
    bhomeIds: [],
    bundle: null,
    isAllBhomes: false,
    rebootAfterUpdate: false,
    enforceImmediateAction: false,
    middlewareVersion: '',
    firmwareVersion: '',
    middlewareUpdateScript: '',
    firmwareBinaryUrl: '',
    cameraServerVersion: '',
};

const BhomeSoftwareUpdate = ({ bhomes, isSuperViewer }) => {
    const connectivityMap = useSelector(state => state.dashboard.connectivityMap);

    const form = useForm({
        defaultValues,
    });
    const {
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setError,
    } = form;
    const dispatch = useDispatch();

    const [isDirectUpdate, bhomeVersion] = useWatch({
        name: ['isDirectUpdate', 'bhomeVersion'],
        control,
    });

    useEffect(() => {
        dispatch(fetchSoftwareBundles());
        dispatch(fetchConnectivityMap());
    }, [dispatch]);

    const bhomeOptions = useMemo(
        () => getBhomeOptions({ bhomes, bhomeVersion, connectivityMap }),
        [bhomes, bhomeVersion, connectivityMap]
    );

    const validateData = data => {
        const {
            isDirectUpdate,
            firmwareVersion,
            firmwareBinaryUrl,
            middlewareVersion,
            middlewareUpdateScript,
            cameraServerVersion,
        } = data;
        if (!isDirectUpdate) {
            return true;
        }
        if (firmwareVersion || firmwareBinaryUrl) {
            return firmwareVersion && firmwareBinaryUrl;
        }
        return middlewareVersion || middlewareUpdateScript || cameraServerVersion;
    };

    const handleSoftwareUpdate = useCallback(
        state => {
            const isValid = validateData(state);
            if (!isValid) {
                return setError(ROOT_ERRORS.FORM_ERROR, {
                    message: 'Not all fields are filled in',
                    type: 'validation',
                });
            }
            const rebootState = state.rebootAfterUpdate;
            const isAllState = state.isAllBhomes;

            const ids = isAllState ? bhomeOptions.map(option => option.value) : state.bhomeIds;
            let body;
            if (!state.isDirectUpdate) {
                body = {
                    ids,
                    bundleId: state.bundle,
                    reboot_after_update: rebootState,
                    enforceImmediateAction: state.enforceImmediateAction,
                };
            } else if (isDirectUpdate && isTestEnv) {
                body = {
                    ids,
                    reboot_after_update: rebootState,
                    software_updates: {},
                    enforceImmediateAction: state.enforceImmediateAction,
                };
                if (state.firmwareVersion && state.firmwareBinaryUrl) {
                    body = {
                        ...body,
                        software_updates: {
                            ...body.software_updates,
                            new_firmware_version: state.firmwareVersion,
                            new_firmware_binary_url: state.firmwareBinaryUrl,
                        },
                    };
                }

                if (state.middlewareVersion) {
                    body = {
                        ...body,
                        software_updates: {
                            ...body.software_updates,
                            new_middleware_version: state.middlewareVersion,
                        },
                    };
                }

                if (state.middlewareUpdateScript) {
                    body = {
                        ...body,
                        software_updates: {
                            ...body.software_updates,
                            new_middleware_update_script: state.middlewareUpdateScript,
                        },
                    };
                }

                if (state.cameraServerVersion) {
                    body = {
                        ...body,
                        software_updates: {
                            ...body.software_updates,
                            new_camera_server_version: state.cameraServerVersion,
                        },
                    };
                }
            }

            dispatch(
                fetchUpdateBhomeSoftware(body, () => {
                    reset({
                        rebootAfterUpdate: rebootState,
                        enforceImmediateAction: state.enforceImmediateAction,
                        isAllBhomes: isAllState,
                    });
                })
            );
        },
        [bhomeOptions, isDirectUpdate, reset, dispatch, setError]
    );

    const handleDirectUpdateChange = useCallback(
        e => {
            reset({
                ...defaultValues,
                isDirectUpdate: e.target.checked,
                bhomeVersion: e.target.checked ? ALL_VERSIONS : null,
            });
        },
        [reset]
    );

    const UpdateComponent = isDirectUpdate ? DirectBhomeUpdate : RegularBhomeUpdate;
    const rootErrors = getRootLevelErrors(errors);

    return (
        <div className="software-update">
            {isTestEnv && (
                <CheckboxFormField
                    name="isDirectUpdate"
                    className="software-bundle-input"
                    onChange={handleDirectUpdateChange}
                    label="Direct Update"
                    control={control}
                />
            )}
            <UpdateComponent form={form} bhomeOptions={bhomeOptions} />
            <div className="error">{rootErrors}</div>
            {!isSuperViewer && (
                <Button className="btn-primary" onClick={handleSubmit(handleSoftwareUpdate)}>
                    Update
                </Button>
            )}
        </div>
    );
};

BhomeSoftwareUpdate.propTypes = {
    bhomes: PropTypes.arrayOf(PropTypes.shape()),
    isSuperViewer: PropTypes.bool,
};

export default BhomeSoftwareUpdate;
