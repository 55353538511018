import PropTypes from 'prop-types';
import { SelectFormField } from '@beewise/hook-form';
import React, { useState } from 'react';
import GridModal from '../GridModal';

const SelectGridField = ({ gridOptions, gridName, onSelect, size, selectedBhomeIds, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <SelectFormField
            backspaceRemovesValue={false}
            components={{
                // eslint-disable-next-line
                Menu: props => (
                    <GridModal
                        {...props}
                        gridOptions={gridOptions}
                        gridName={gridName}
                        onSelect={onSelect}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        size={size}
                        selectedBhomeIds={selectedBhomeIds}
                    />
                ),
            }}
            menuIsOpen={isOpen}
            onMenuOpen={() => setIsOpen(true)}
            {...props}
        />
    );
};

SelectGridField.propTypes = {
    gridOptions: PropTypes.shape(),
    gridName: PropTypes.string,
    onSelect: PropTypes.func,
    size: PropTypes.string,
    selectedBhomeIds: PropTypes.arrayOf(PropTypes.string),
};

export default SelectGridField;
